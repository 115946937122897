<template>
	<!-- 新增检验单 -->
	<div :class="themeClass" class="contBox margin-t-10 bg-fff">
		<div class="newPersonbtn-title font-size18 font-weight700 margin-t-4 padding-b-13 font-color-000">检验单申请</div>
		<div class="newPersonbtn-Box">
			<el-form :model="addForm" :rules="rules" ref="addForm" label-width="130px" class="demo-ruleForm">
				<div class="text-center font-size16 margin-b-25">{{pageTitle}}</div>

				<el-form-item label="发货日期">
					<div class="inputBor">
						<el-date-picker v-model="fBeginTime" type="date" placeholder="请选择开始日期">
						</el-date-picker>
						<span>至</span>
						<el-date-picker v-model="fEndTime" type="date" placeholder="请选择结束日期">
						</el-date-picker>
					</div>
				</el-form-item>
				<el-form-item label="申请类型" prop="fApplyType">
				    <el-radio-group v-model="addForm.fApplyType"  @input="inputRadio">
				      <el-radio v-for="(item, index) in listApplyType" :key="index" :label="item.typeID">
				      		{{item.name}}
				      </el-radio>
				    </el-radio-group>
				  </el-form-item>
				<el-form-item label="商品全称" prop="fGoodsFullName">
					<el-input v-model="addForm.fGoodsFullName" placeholder="请选择商品" :disabled="true">
						<el-button slot="append" @click="selectG">选择商品</el-button>
					</el-input>
				</el-form-item>
				<el-form-item label="发货日期" prop="fGoodsFullName" class="fKeepDate">
					<el-date-picker v-model="addForm.fKeepDate" type="date" :disabled="true" placeholder="请选择商品">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="出厂编号" prop="fGoodsFullName">
					<el-input v-model="addForm.fDeliveryOrderNumber" placeholder="请选择商品" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="实发数量" prop="fGoodsFullName">
					<el-input v-model="addForm.fScheduleVanAmount" placeholder="请选择商品" :disabled="true">
						<template slot="append">吨</template>
					</el-input>
				</el-form-item>
				<el-form-item label="车牌号" prop="fPlateNumber">
					<el-input v-model="addForm.fPlateNumber" placeholder="请输入车牌号" :disabled="true">
					</el-input>
				</el-form-item>
				<el-form-item label="申请数量" prop="fAmount">
					<el-input v-model="addForm.fAmount" placeholder="请输入申请数量" @input="setOnlyMoney"></el-input>
				</el-form-item>
				<el-form-item label="工程名称" prop="fqCustomerName">
					<el-input v-model="addForm.fqCustomerName" placeholder="请输入工程名称"></el-input>
				</el-form-item>
				<!--  <el-form-item style="width: 100%;padding: 0;">
				<el-button :loading="subStatus" class="comBtn font-color-fff bg-theme text-center border-theme radius2" @click="submitForm()">提交审核</el-button>
				<el-button :loading="addStatus" class="comBtn font-color-fff bg-theme text-center border-theme radius2" @click="save()">保存</el-button>
				<el-button class="comBtn font-color-fff text-center border-theme radius2 bg-fff color-theme margin-r-8"  @click="resetForm()">取消</el-button>
			  </el-form-item> -->
				<div style="text-align: center;">
					<el-button :loading="addStatus"
						class="comBtn font-color-fff bg-theme text-center border-theme radius2" @click="save()">保存
					</el-button>
					<el-button :loading="subStatus"
						class="comBtn font-color-fff bg-theme text-center border-theme radius2" @click="submitForm()">
						提交审核</el-button>
					<el-button
						class="comBtn font-color-fff text-center border-theme radius2 bg-fff color-theme margin-r-8"
						@click="resetForm()">取消</el-button>
				</div>
			</el-form>

			<div class="bg-box"></div>
		</div>
		<addGoodsDialog :showModel="dialogVisible" @change="changeModel" @chooseData="handleAddDialogChange"
			:fBeginTime="getDate(fBeginTime)" :fEndTime="getDate(fEndTime)" :fApplyType="addForm.fApplyType"></addGoodsDialog>
	</div>
</template>

<script>
	import addGoodsDialog from '@/views/business/other/addGoodsDialog';
	import {
		mapGetters
	} from 'vuex';
	export default {
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		components: {
			addGoodsDialog
		},
		data() {
			var checkFAmount = (rule, value, callback) => {
				if (this.formmodel.fScheduleVanAmount) {
					if (parseInt(value) > parseInt(this.formmodel.fScheduleVanAmount)) {
						callback(new Error("申请数量需要小于等于实发数量"));
					} else {
						callback();
					}
				} else {
					callback(new Error("请选择出库商品"));
				}

			};
			return {
				listApplyType:[{
					name:"28天",
					typeID:2,
				},{
					name:"3天",
					typeID:1,
				},{
					name:"合格证",
					typeID:3,
				}],
				subStatus: false,
				addStatus: false,
				dialogVisible: false,
				fBeginTime: '',
				fEndTime: '',
				value1: '',
				pageTitle: '申请检验单信息',
				addForm: {
					fApplyType:2,
				},
				rules: {
					fGoodsFullName: [{
						required: false,
						message: '请选择出库商品',
						trigger: ['blur', 'change']
					}],
					fAmount: [{
							required: true,
							message: '请输入申请数量',
							trigger: 'blur'
						},
						{
							validator: checkFAmount,
							trigger: 'blur'
						}
					],
					fqCustomerName: [{
						required: true,
						message: '请输入工程名称',
						trigger: 'blur'
					}],
				},
				goodsList: false,
				formmodel: {},
				fBillNumber: ''
			}
		},
		mounted() {
			this.getNmber();
		},
		methods: {
			inputRadio(e){
				this.addForm.fApplyType=e;
				this.addForm.fGoodsFullName="";
				this.formmodel.fGoodsID = ""
			},
			setOnlyMoney() {
				this.$nextTick(() => {
					let val = this.addForm.fAmount.toString();
					val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
					val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
					val = val.replace(/^0+\./g, '0.');
					val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val
					val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || ''
					this.addForm.fAmount = val;
				});
			},
			selectG() {
				if (this.fBeginTime != '' && this.fEndTime != '') {
					if (this.getDate(this.fEndTime) < this.getDate(this.fBeginTime)) {
						return this.$message('结束日期不能早于开始日期');
					}
				} else {
					return this.$message('请选择发货日期');
				}
				if(this.addForm.fApplyType == "" || this.addForm.fApplyType == null || this.addForm.fApplyType == undefined){
					return this.$message('请选择申请类型');
				}
				this.dialogVisible = true;

			},
			handleAddDialogChange(val) {
				// 回传数据
				this.formmodel = val[0];
				this.addForm.fDeliveryOrderNumber = val[0].fDeliveryOrderNumber;
				this.addForm.fScheduleVanAmount = val[0].fScheduleVanAmount;
				this.addForm.fGoodsFullName = val[0].fGoodsFullName;
				this.addForm.fKeepDate = val[0].fKeepDate;
				this.addForm.fPlateNumber=val[0].fPlateNumber
				console.log(val);
			},
			changeModel(val) {
				this.dialogVisible = val;
			},
			getNmber() {
				this.ApiRequestPost(
					"/api/mall/ebsale/check-list-apply-record/get-bill-number", {}
				).then(
					(res) => {
						this.fBillNumber = res.obj;
					},
					(error) => {}
				);
			},
			submitForm() {
				if (this.formmodel.fGoodsID == "") {
					return this.$message('请选择商品');
				}
				if (this.addForm.fAmount == 0) {
					return this.$message('申请数量不能为0');
				}
				this.$refs['addForm'].validate((valid) => {
					if (valid) {
						this.subStatus = true;
						let params = [{
							"fDeliveryData": this.getTime(this.formmodel.fKeepDate),
							"fAmount": this.addForm.fAmount,
							"fShopUnitID": this.formmodel.fCompanyID,
							"fGoodsID": this.formmodel.fGoodsID,
							"fBillNumber": this.fBillNumber,
							"fqCustomerName": this.addForm.fqCustomerName,
							"fBillID": "",
							"fWareNumber": this.addForm.fDeliveryOrderNumber,
							fPlateNumber: this.addForm.fPlateNumber,
							fApplyType:this.addForm.fApplyType
						}];

						this.ApiRequestPut(
							'/api/mall/ebsale/check-list-apply-record/submit-auditing', params
						).then(
							(res) => {
								this.subStatus = false;
								this.$router.push({
									path: "/businessme/InspectionOrder",
								});
							},
							(error) => {
								this.subStatus = false;
							}
						);
					} else {
						this.subStatus = false;
					}
				})

			},
			save() {
				if (this.formmodel.fGoodsID == "") {
					return this.$message('请选择商品');
				}
				if (this.addForm.fAmount == 0) {
					return this.$message('申请数量不能为0');
				}
				if (this.addForm.fqCustomerName == "") {
					return this.$message.info('工程名称不能为空');
				}
				this.$refs['addForm'].validate((valid) => {
					if (valid) {

						this.addStatus = true;
						let params = {
							"fDeliveryData": this.getDate(this.formmodel.fKeepDate),
							"fAmount": this.addForm.fAmount,
							"fShopUnitID": this.formmodel.fCompanyID,
							"fGoodsID": this.formmodel.fGoodsID,
							"fBillNumber": this.fBillNumber,
							"fqCustomerName": this.addForm.fqCustomerName,
							"fWareNumber": this.addForm.fDeliveryOrderNumber,
							"fBillID": "",
							fPlateNumber: this.addForm.fPlateNumber,
							fApplyType:this.addForm.fApplyType

						};
						this.ApiRequestPost(
							'/api/mall/ebsale/check-list-apply-record/create', params
						).then(
							(res) => {
								this.addStatus = false;
								this.$router.push({
									path: "/businessme/InspectionOrder",
								});
							},
							(error) => {
								this.addStatus = false;
							}
						);
					} else {
						this.addStatus = false;
					}
				});



			},
			resetForm() {
				this.$router.push({
					path: "/businessme/InspectionOrder",
				});
			}
		}
	}
</script>
<style lang="scss" scoped="scoped">
	/deep/.el-form-item__content {
		height: 30px !important;
		line-height: 32px;
	}

	.el-form::v-deep {
		.el-form-item__label {
			height: 32px;
			line-height: 32px;
		}
	}

	/deep/.el-input-group__append {
		border-top-right-radius: 4px !important;
		border-bottom-right-radius: 4px !important;
		border: 1px solid #f2f2f2;
	}

	.bagm {
		@include themify($themes) {
			background-color: themed("themes_color");
		}

		color:#fff;
	}

	.btncl {
		height: 28px;
		line-height: 28px;
		color: #fff;

		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.inputBor::v-deep {
		// height: 30px;
		display: flex;

		// border: 1px solid #f2f2f2;
		/deep/.el-input__inner {
			// border: 0!important;
		}

		span {
			// line-height: 30px;
		}

		.el-input__icon {
			line-height: 0;
		}
	}

	.fKeepDate::v-deep {
		.el-input__icon {
			line-height: 0;
		}
	}

	.bg-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.contBox {
		width: 83%;
	}

	.demo-ruleForm {
		width: 462px;
		margin: 0px auto;
		position: relative;
		z-index: 1;
	}

	.newPersonbtn-Box {

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 100%;
		}

		.el-date-editor .el-range-input,
		.el-date-editor .el-range-separator {
			margin-top: -13px;
			color: #C0C4CC !important;
		}
	}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.newPersonbtn-title {
		// width: 73px;
		height: 22px;
		padding: 6px 0px;
	}

	::v-deep .el-input__inner,
	.el-select {
		width: 100%;
		height: 32px;
		line-height: 32px;
	}

	/* 按钮 */
	.comBtn {
		width: 130px;
		height: 38px;
		// line-height: 38px;
		color: #fff;
		cursor: pointer;
		display: inline-block;
		margin-top: 20px;
		margin-right: 10px;
	}
</style>
